import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';

import { EnNavHeader } from './EnNavHeader';
import { EnNavFooter } from './EnNavFooter';

import { getClientId } from './utils/analyticsclientIdUtils';
import { encryptData } from './utils/encryptionUtils';
import { API_URL } from './utils/constants';

class EnAuthForgotDetailClass extends Component {

    constructor(props) {
        super(props);
        this.state = {
            memberInfo: null,
            codeRegister: null,
            password1: '',
            password2: '',
            registerMsg: '',
            registerMsgPwd: '',
            registerMsgPwd2: '',
            showPasswordTips: false,
            submitting: false,
            renderCheck: false
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        const searchParams = new URLSearchParams(this.props.location.search);
        const codeRegister = searchParams.get('codereg');
        if (!codeRegister) {
            const dataToSend = { reason: "No code!!" };
            this.props.history.replace('/forgoterr', dataToSend);
            return;
        }

        // Update state and call API to get member information
        this.setState({ codeRegister }, () => {
            this.callApiRegisterInfo(codeRegister);
        });

        {
            // Google analytic
            const documentPath = "ForgotPwd - detail"; // Gets the current URL path
            const clientId = getClientId(); // Use the getClientId function

            // Push data to the GTM dataLayer
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'pageview',
                page: documentPath,
                clientId: clientId,
            });
        }
    }

    async callApiRegisterInfo(codeRegister) {
        // Add the wait cursor class to the body element
        document.body.classList.add('wait-cursor');

        // Generate Unix timestamp
        const timestamp = Math.floor(Date.now() / 1000);

        const payload = { codereg: codeRegister, RefLang: "en", Timestamp: timestamp };
        const encryptedPayload = encryptData(payload);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ Data: encryptedPayload })
        };

        try {
            const response = await fetch(`${API_URL}/api/CrmForgotStepChk`, requestOptions);
            const data = await response.json();

            if (data) {
                if (data.responseCode === "OK") {
                    this.setState({ memberInfo: data, renderCheck: true }, () => {
                        // Initialize this java after loaded
                        window.$(".toggle-password").click(function () {
                            window.$(this).toggleClass("fa-eye fa-eye-slash");
                            var input = window.$(window.$(this).attr("toggle"));
                            if (input.attr("type") === "password") {
                                input.attr("type", "text");
                            } else {
                                input.attr("type", "password");
                            }
                        });
                    });
                }
                else {
                    const dataToSend = { reason: data.responseMessage };
                    this.props.history.replace('/forgoterr', dataToSend);
                }
            }
            else {
                const dataToSend = { reason: "No data!!" };
                this.props.history.replace('/forgoterr', dataToSend);
            }
        } catch (error) {
            this.setState({ renderCheck: true });
            console.error('API call error :', error);
        } finally {
            document.body.classList.remove('wait-cursor'); // Remove the wait cursor class
        }
    }

    handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        if (type === 'checkbox') {
            this.setState({ [name]: checked });
        } else {
            let errors = '';
            if (name === "password1") {
                errors = this.validatePassword(value);
            }

            this.setState({ [name]: value, registerMsgPwd: errors });
        }
    };

    handleSubmit = async e => {
        e.preventDefault();
        const { password1, password2, codeRegister } = this.state;
        {
            var errorChk = false;
            //check form condition
            const errors = this.validatePassword(password1);
            if (errors) {
                this.setState({ registerMsgPwd: errors });
                errorChk = true;
            }

            if (password2 === "") {
                this.setState({ registerMsgPwd2: "Please Input Confirm Password" });
                errorChk = true;
            }

            if (password1 !== password2) {
                this.setState({ registerMsgPwd2: "Confirm Password must match with Password" });
                errorChk = true;
            }

            if (errorChk) {
                return;
            }
        }

        // Generate Unix timestamp
        const timestamp = Math.floor(Date.now() / 1000);

        // Prepare and encrypt the payload
        const payload = { CodeReg: codeRegister, CurrentPassword: password1, RefLang: 'en', Timestamp: timestamp };
        const encryptedPayload = encryptData(payload);

        // Prevent double-clicking by disabling the submit button and changing cursor to 'wait'
        this.setState({ submitting: true });

        // Add the wait cursor class to the body element
        document.body.classList.add('wait-cursor');

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ Data: encryptedPayload })
        };

        try {
            const response = await fetch(`${API_URL}/api/CrmForgotStep02`, requestOptions);
            const data = await response.json();
            document.body.classList.remove('wait-cursor'); // Remove the wait cursor class

            if (data.responseCode === "OK") {
                const dataToSend = { responseData: data };
                this.props.history.replace('/forgotthx', dataToSend);
            }
            else {
                //const dataToSend = { responseData: data };
                //this.props.history.replace('/forgoterr', dataToSend);

                // Enable the submit button and change cursor back to 'auto' after form submission completes
                this.setState({ submitting: false });
                document.body.classList.remove('wait-cursor'); // Remove the wait cursor class

                this.setState({ registerMsg: data.responseMessage });
            }
        } catch (error) {
            // Enable the submit button and change cursor back to 'auto' after form submission completes
            this.setState({ submitting: false });
            document.body.classList.remove('wait-cursor'); // Remove the wait cursor class

            console.error('Error :', error);
            // Handle error appropriately, e.g., show an error message to the user
        } finally {

        }
    };

    validatePassword = (password) => {
        const minLength = 9;
        const maxLength = 15;
        const numberRegex = /[0-9]/;
        const capitalLetterRegex = /[A-Z]/;
        const lowerCaseLetterRegex = /[a-z]/;
        const specialCharRegex = /[!@#$*;&]/;

        if (password.length === 0) {
            return 'Please Input Password.';
        }
        if (password.length < minLength || password.length > maxLength) {
            return `Password must be between ${minLength} and ${maxLength} characters long. `;
        }
        if (!numberRegex.test(password)) {
            return 'Password must contain at least one number. ';
        }
        if (!capitalLetterRegex.test(password)) {
            return 'Password must contain at least one capital letter. ';
        }
        if (!lowerCaseLetterRegex.test(password)) {
            return 'Password must contain at least one lower case letter. ';
        }
        if (!specialCharRegex.test(password)) {
            return 'Password must contain at least one special character (e.g., !,@,#,$,*,;,&). ';
        }

        return ''; // Return an empty string if all checks pass
    };

    handleFocus = () => {
        this.setState({ showPasswordTips: true });
    };

    handleBlur = () => {
        this.setState({ showPasswordTips: false });
    };

    render() {
        const { memberInfo, password1, password2, registerMsg, submitting, showPasswordTips, registerMsgPwd, registerMsgPwd2, renderCheck } = this.state;
        return (
            <>
                <EnNavHeader />
                {renderCheck ? (
                    <>
                        {memberInfo ? (
                            <main id="main">
                                <section className="top-sr-bg bg-gray1">
                                    <div className="container">
                                        <div className="row justify-content-between">
                                            <div className="col col-md-6">
                                                <h1 className="text-headpage text-white mb-0 pt-3 pb-3 "><span className="d-none">Starbucks&reg; Rewards</span><img src="media/rewards/starbucks-rewards-logo-2.png" alt="Starbucks&reg; Rewards" className="img-fluid" style={{ maxWidth: '158px' }} /></h1>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section className="section-create-account overflow-hidden pb-5">
                                    <div className="container p-3 p-md-0">
                                        <div className="row">
                                            <div className="col-md-12 mb-5 pt-3 text-start">
                                                <h2 className="h3 mt-5 mb-4 fw-bold text-dark-212121">Reset password</h2>
                                                <form className="form-sr" onSubmit={this.handleSubmit}>
                                                    <div className="row">
                                                        <div className="col-md-4 mb-3">
                                                            <label className="form-label">Password</label>
                                                            <div className="input-group mb-3">
                                                                <input
                                                                    id="password-field"
                                                                    type="password"
                                                                    className="form-control border-end-0"
                                                                    placeholder="*Password"
                                                                    name="password1"
                                                                    value={password1}
                                                                    onChange={this.handleChange}
                                                                    onFocus={this.handleFocus}
                                                                    onBlur={this.handleBlur}
                                                                />
                                                                <span className="input-group-text"><i toggle="input[name='password1']" className="fa fa-fw fa-eye-slash toggle-password" /></span>
                                                                <div className="invalid-feedback" style={{ display: 'block' }}>{registerMsgPwd}</div>
                                                                {showPasswordTips && (
                                                                    <div id="new-password-field-tips" className="passwordtips-box">
                                                                        <h4 className="h6 text-decoration-underline">Password Tips</h4>
                                                                        <p className="form-text">
                                                                            From 9 to 15 characters<br />
                                                                            At least one number<br />
                                                                            At least one capital letter<br />
                                                                            At least one lower case letter<br />
                                                                            At least one Special characters eg. !,@,#$*,&
                                                                        </p>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-4 mb-3">
                                                            <label className="form-label">Confirm Password</label>
                                                            <div className="input-group mb-3">
                                                                <input id="password-confirm-field" type="password" className="form-control border-end-0" placeholder="*Confirm Password" name="password2" value={password2} onChange={this.handleChange} />
                                                                <span className="input-group-text"><i toggle="input[name='password2']" className="fa fa-fw fa-eye-slash toggle-password" /></span>
                                                                <div className="invalid-feedback" style={{ display: 'block' }}>{registerMsgPwd2}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="text-start mt-4">
                                                        <button type="submit" className="btn btn-primary rounded-pill px-4 text-decoration-none" disabled={submitting}>
                                                            {submitting ? 'Reset password...' : 'Reset password'}
                                                        </button>
                                                    </div>
                                                    <div className="text-start mt-2 text-alert-2">
                                                        {registerMsg}
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </main>
                        ) : (
                            <>
                                    <main id="main">
                                        <section className="top-sr-bg bg-gray1">
                                            <div className="container">
                                                <div className="row justify-content-between">
                                                    <div className="col col-md-6">
                                                        <h1 className="text-headpage text-white mb-0 pt-3 pb-3 "><span className="d-none">Starbucks&reg; Rewards</span><img src="media/rewards/starbucks-rewards-logo-2.png" alt="Starbucks&reg; Rewards" className="img-fluid" style={{ maxWidth: '158px' }} /></h1>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section className="section-create-account overflow-hidden pb-5">
                                            <div className="container p-3 p-md-0">
                                                <div className="row">
                                                    <div className="col-md-12 mb-4 pt-3 text-center">
                                                        <p className="text-gray">Sorry, your password reset link expired. Please try that again. Thank you..</p>
                                                        <p className="text-gray"><Link to="/forgotpwd">Click here to forgot password.</Link></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </main>
                            </>
                        )}
                    </>
                ) : (
                    <>
                        {/*Loading*/}
                        <div className="col-md-5">
                            <div className="text-center" style={{ height: '160px' }} />
                            <div className="text-center mt-5 pt-5 mb-5 pb-5">
                                <h3 className="text-white">LOADING ...</h3>
                                <img src="media/loading-gif.gif" alt="Loading..." width={80} className="img-fluid" />
                            </div>
                            <div style={{ height: '100px' }} />
                        </div>
                    </>
                )}
                <EnNavFooter />
            </>
        );
    }
}

export const EnAuthForgotDetail = withRouter(EnAuthForgotDetailClass);
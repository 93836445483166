import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { EnNavHeader } from './EnNavHeader';
import { EnNavFooter } from './EnNavFooter';

import { getClientId } from './utils/analyticsclientIdUtils';

class EnAuthJoinDetailErrClass extends Component {
    constructor(props) {
        super(props);
        const { history } = this.props;
        const dataToSend = history.location.state;

        this.state = {
            reasonToShow: dataToSend?.reason || '', // Set initial state here
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);        

        {
            // Google analytic
            const documentPath = "Join - error"; // Gets the current URL path
            const clientId = getClientId(); // Use the getClientId function

            // Push data to the GTM dataLayer
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'pageview',
                page: documentPath,
                clientId: clientId,
            });
        }
    }

    render() {
        const { reasonToShow } = this.state;
    return (
        <>
            <EnNavHeader />

            <main id="main">
                <section className="top-sr-bg bg-gray1">
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col col-md-6">
                                <h1 className="text-headpage text-white mb-0 pt-3 pb-3 "><span className="d-none">Starbucks&reg; Rewards</span><img src="media/rewards/starbucks-rewards-logo-2.png" alt="Starbucks&reg; Rewards" className="img-fluid" style={{ maxWidth: '158px' }} /></h1>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section-sign-in overflow-hidden pb-5">
                    <div className="container-fluid p-3 p-md-0">
                        <div className="row">
                            <div className="col-md-12 mb-4 pt-3 text-center">
                                <p className="text-gray">Error.</p>
                                <p className="text-gray">{reasonToShow}</p>
                            </div>
                        </div>                        
                    </div>
                </section>
            </main>

            <EnNavFooter />
      </>
    );
  }
}

export const EnAuthJoinDetailErr = withRouter(EnAuthJoinDetailErrClass);
import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';

export class ThNavFooter extends Component {
    static displayName = ThNavFooter.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true,
            isAuthenticated: !!sessionStorage.getItem('username') // Check if username exists in session storage
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {
        const { isAuthenticated } = this.state;

        return (
            <>
                <footer id="footer" className="text-muted py-5 pb-4 bg-footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3 mb-4"><span className="footer-menu-title"><a href="https://www.starbucks.co.th/th/about-us/#About-Us" rel="noopener noreferrer">เกี่ยวกับเรา</a></span>
                                <div>
                                    <ul>
                                        <li><a href="https://www.starbucks.co.th/th/about-us/#our-heritage" rel="noopener noreferrer">ความเป็นมาของเรา</a></li>
                                        <li><a href="https://www.starbucks.co.th/th/about-us/our-company/" rel="noopener noreferrer">บริษัทของเรา</a></li>
                                        <li><a href="https://www.starbucks.co.th/th/responsibility/" rel="noopener noreferrer">ความรับผิดชอบต่อสังคม</a></li>
                                        <li><a href="https://www.starbucks.co.th/th/career/" rel="noopener noreferrer">ร่วมงานกับสตาร์บัคส์</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-3 mb-4"><span className="footer-menu-title"><a href="https://www.starbucks.co.th/th/coffee-tea-at-home/" rel="noopener noreferrer">เมล็ดกาแฟและชาสุดพิเศษ</a></span>
                                <div>
                                    <ul>
                                        <li><a href="https://www.starbucks.co.th/th/coffee-quality/" rel="noopener noreferrer">เรื่องราวกาแฟคุณภาพเยี่ยม</a></li>
                                        <li><a href="https://www.starbucks.co.th/th/coffee-tea-at-home/" rel="noopener noreferrer">เมล็ดกาแฟและชาของเรา</a></li>
                                        <li><a href="https://www.starbucks.co.th/th/how-to-brew/" rel="noopener noreferrer">วิธีชงกาแฟ</a></li>
                                        <li><a href="https://www.starbucks.co.th/th/coffee-workshop/" rel="noopener noreferrer">คอฟฟี่ เวิร์คช็อป</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-3 mb-4">
                                <ul>
                                    <li><Link to="/ThHome">สตาร์บัคส์ รีวอร์ด</Link></li>
                                    <li><a href="https://www.starbucks.co.th/th/promotions/" rel="noopener noreferrer">โปรโมชั่น</a></li>
                                    <li><a href="https://www.starbucks.co.th/th/starbucks-card-corporate-sale/" rel="noopener noreferrer">ลูกค้าองค์กร</a></li>
                                    <li><a href="https://www.starbucks.co.th/th/cashless-store/" rel="noopener noreferrer">ข้อมูลเพิ่มเติม</a></li>
                                    <li><a href="https://www.starbucks.co.th/th/etax/" rel="noopener noreferrer">ใบกำกับภาษีอิเล็กทรอนิกส์</a></li>
                                    <li><a href="https://www.starbucks.co.th/th/customer-service/" rel="noopener noreferrer">ติดต่อเรา</a></li>
                                </ul>
                            </div>
                            <div className="col-md-3 mb-4">
                                <ul className="footer-lang text-start text-md-end">
                                    <li><Link to="/" ><img src={require("./media/us.png")} alt="English" />English</Link></li>
                                    <li><Link to="/ThHome" ><img src={require("./media/th.png")} alt="ไทย" />ไทย</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </footer>
                <div className="footer-widgets footer footer-2">
                    <div className="row m-0 p-0">
                        <div className="col col-lg-12 m-0 p-0">
                            <div className="container">
                                <section className="section-footer-2">
                                    <div className="section-content relative">
                                        <div className="row row-collapse py-4">
                                            <div className="col-md-6 px-0 mb-4 mb-md-0">
                                                <div className="col-inner">
                                                    <div className="box-footer-social text-center text-md-start">
                                                        <a href="https://www.facebook.com/StarbucksThailand/" rel="noopener noreferrer" target="_blank"><img className="img-fluid" src={require("./media/rewards/icon-facebook@2x.png")} alt="facebook" width={100} height={100} /></a>
                                                        <a href="https://www.instagram.com/starbucksthailand/" rel="noopener noreferrer" target="_blank"><img className="img-fluid" src={require("./media/rewards/icon-ig@2x.png")} alt="Instagram" width={100} height={100} /></a>
                                                        <a href="https://line.me/R/ti/p/%40853tzvmu" rel="noopener noreferrer" target="_blank"><img className="img-fluid" src={require("./media/rewards/icon-line@2x.png")} alt="LINE APP" width={100} height={100} /></a>
                                                        <a href="#" rel="noopener noreferrer" target="_blank"><img className="img-fluid" src={require("./media/rewards/icon-tiktok@2x.png")} alt="Tiktok" width={100} height={100} /></a>
                                                        <a href="https://www.youtube.com/@starbucksthailandofficial" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={require("./media/rewards/icon-youtube@2x.png")} alt="Youtube" width={100} height={100} /></a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 px-0 mb-4 mb-md-0">
                                                <div className="col-inner">
                                                    <div className="box-mobile-app-icons text-center text-md-end">
                                                        <a href="https://play.google.com/store/apps/details?id=com.starbucks.thailand" rel="noopener noreferrer" target="_blank"><img className="img-fluid" src={require("./media/rewards/btn-app-store.png")} alt="" width={148} height={48} /></a>
                                                        <a href="https://itunes.apple.com/th/app/starbucks-thailand/id898062370?mt=8" rel="noopener noreferrer" target="_blank"><img className="img-fluid" src={require("./media/rewards/btn-google-play.png")} alt="" width={148} height={48} /></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="absolute-footer dark">
                    <div className="container clearfix">
                        <p className="float-none float-md-end mb-1 text-center text-md-end">
                            ©{new Date().getFullYear()}  Coffee Concepts Retail Co.,Ltd. All rights reserved.
                        </p>
                        <ul id="menu-footer-bottom-menu" className="mb-1 text-center text-md-start">
                            <li><a href="https://www.starbucks.co.th/th/online-policies/web-accessibility/" rel="noopener noreferrer">โอกาสในการเข้าถึงเว็บ</a></li>
                            <li><a href="https://www.starbucks.co.th/th/online-policies/privacy-notice/" rel="noopener noreferrer">คำชี้แจงความเป็นส่วนตัว</a></li>
                            <li><a href="https://www.starbucks.co.th/th/online-policies/terms-of-use/" rel="noopener noreferrer">เงื่อนไขการใช้งาน</a></li>
                            <li><a href="https://www.starbucks.co.th/th/site-map/" rel="noopener noreferrer">แผนผังไซต์</a></li>
                        </ul>
                    </div>
                </div>
                <div className="d-none">
                    <img src="./media/rewards/xl-hero-desktop.webp"></img>
                </div>
            </>
        );
    }
}
